import Head from "next/head";

export default function CommonHead() {
  return (<Head>
    <title>Couch Software - Your business insights at your fingertips.</title>
    <link rel="icon" href="/favicon.ico"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
    <link rel="manifest" href="/site.webmanifest"/>
    <meta name="msapplication-TileColor" content="#da532c"/>
    <meta name="theme-color" content="#ffffff"/>
    <meta
      name="description"
      content="Your business insights at your fingertips."
    />
  </Head>)
}
